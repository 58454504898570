import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/index',
    name: 'layout',
    redirect: '/index/home',
    component: () => import("@/layout/index"),
    children:[
      {
        path: 'home',
        name: 'Home',
        component: () => import("@/views/home/index")
      },
      {
        path: 'about',
        name: 'About',
        component: () => import("@/views/about/index"),
      },
      {
        path: 'coreBusiness',
        name: 'CoreBusiness',
        component: () => import("@/views/core-business/index"),
      },
      {
        path: 'partner',
        name: 'Partner',
        component: () => import("@/views/partner/index"),
      },
      {
        path: 'news',
        name: 'News',
        component: () => import("@/views/news/index"),
      },
      {
        path: 'detailPage',
        name: 'DetailPage',
        component: () => import("@/views/news/detailPage"),
      },
      {
        path: 'travelServe',
        name: 'TravelServe',
        component: () => import("@/views/travel-serve/index"),
      },
      {
        path: 'contactUs',
        name: 'ContactUs',
        component: () => import("@/views/contact-us/index"),
      },

    ]
  },
  {
    path: '*',
    redirect: '/index/home',
  }
]

const router = new VueRouter({
  routes
})

export default router

export default {
  A001: "首页",
  A002: "关于我们",
  A003: "核心业务",
  A004: "差旅服务",
  A005: "云上快讯",
  A006: "合作伙伴",
  A007: "联系我们",
  A008: "差旅预定",
  A009: "企业老板",
  A010: "部门经理",
  A011: "财务&会计",
  A012: "出行人",
  A013: "查看更多",
  A014: "信息安全，无泄漏",
  A015: "尊享优惠,无高价",
  A016: "退改透明，无增项",
  A017: "贴心出行,无担忧",
  A018: "航空公司",
  A019: "签约酒店",
  A020: "用车平台",
  A021: "国际签约酒店",
  A022: "国际覆盖地区",
  A023: "国内签约酒店",
  A024: "国内覆盖城市",
  A025: "云上陕西订票移动端，让你的出行更加便捷",
  A026: "公司简介",
  A027: "企业合作",
  A028: "公司简介",
  A029: "机票",
  A030: "用车",
  A031: "火车票",
  A032: "签证",
  A033: "酒店",
  A034: "保险",
  A035: "地址",
  A036: "其他平台",
  A037: "公众号",
  A038: "APP扫码下载",
  A039: "以客户为中心，以企业为载体，创新的精神，为企业提供个性化服务",
  A040: "企业差旅预订平台",
  A041: "进入系统",
  // A042: "企业差旅预订平台",
  A043: "多种结算模式",
  A044: "极速改签退款",
  A045: "财务数据入账",
  A046: "差旅极速审批",
  A047: "按客户需求提供方案",
  A048: "全网最低票价",
  A049: "PC端",
  A050: "移动端",
  A051: "云上易行商旅",
  A052: "全网最优票价",
  A053: "便捷审批流程",
  A054: "签证一键办理",
  A055: "节省差旅开支",
  A056: "降低管理成本",
  A057: "提高企业效率",
  A058: "扫码下载移动端",
  A059: "国际航协认可证",
  A060: "营业资格证书",
  A061: "去订机票",
  A062: "去订酒店",
  A063: "去订火车票",
  A064: "去订车",
  A065: "国内国际100多家航司，机票实时查询,多渠道预订，大客户协议托管退改灵活，与中国航信IBE数据直连，一分钟内快速报价，3-5分钟内出票，具有单程、往返、正常舱位、特价舱位、联程预订功能",
  A066: "提供全国40000多家，全球1600多家酒店预订，房态实时查询预订，确保到店有房，房源有保障。企业协议酒店托管，房价、星级、城市管控。优化协议酒店，酒店差旅政策严格执行，酒店比价系统，首选酒店优先推荐，不符合差旅政策的追踪与分析。",
  A067: "系统全面接入12306平台资源，可查订全国各地始发高铁、动车、普通火车票。支持12306账号预订代订，支持在线订/退/改作，余票监控、抢票、占座。",
  A068: "旅行、商务出行多种车型用车服务，满足企业和客户不同用车场景需求；7*24小时在线优质服务保障. 接入国内主流用车服务商（如滴滴打车等）覆盖全国各大主要城市。",
  A069: "聚合资源，提升审核效率",
  A070: "快速比价，提升预订效率",
  A071: "全流程管控，提升费用合规性",
  A072: "不垫钱，不借款，提升差旅体验",
  A073: "信息合规透明，实时查看数据",
  A074: "月结，预存，现付多种结算模式",
  A075: "差旅解决方案",
  A076: "差旅管理流程",
  A077: "六大管家式服务",
  A078: "航班官方数据接入",
  A079: "实时接入中国民航信息系统，保障航班价格数据同步对接",
  A080: "酒店官方数据接入",
  A081: "实时接入酒店集团、单体酒店房价系统，随时维护房价",
  A082: "订房价格自动对比",
  A083: "对代收代付酒店，系统自动对比大型订房中心房价，保障客户利益",
  A084: "MIS数据处理",
  A085: "所有接入、接出数据均源自总部MIS系统，并通过该系统完成数据交换，保障呼叫中心、外派坐席互联网接入三网混搭",
  A086: "CRM个性化服务",
  A087: "完善的CRM客户关系管理系统，可以识别客户身份，分析记录客户出行习惯，特殊要求等，为客户提供更为个性化的服务",
  A088: "客户行程关怀系统",
  A089: "客户关怀系统：预定、出票后短信通知及起飞前提醒、延误提醒及到达地天气预报",
  A090: "7×24小时运营中心",
  A091: "网络预订",
  A092: "差旅管理与预订系统客户端嵌套",
  A093: "节省开支",
  A094: "提升企业效率",
  A095: "增强员工出行体验",
  A096: "数据专业分析",
  A097: "企业支出全流程管理节省超过30%的差旅成本",
  A098: "企业支付、统一结算降低超过95%的发票处理量",
  A099: "一站式消费100%体验线下消费报销更智能、更便捷",
  A100: "实时、多维度数据报告帮助企业提升100%管理效率",
  A101: "业务咨询",
  A102: "售后咨询",
  A103: "公司邮箱",
  A104: "公司地址",
  A105: "智能诊断，秒级解答，为您推荐最佳解决方案或匹配合适的人工服务",
  A106: "7×24小时热线服务，为您提供专业的售后支持",
  A107: "智能客服在线 7×24 小时",
//   A108: "智能客服在线 7×24 小时",
  A109: "咨询时间（9:00-24:00）",
  A110: "移动端下载",
  A111: "每日新用户下载量",
  A112: "用户好评率",
  A113: "月平台下单成交量",
  A114: "云上国际是陕西云上科技集团有限公司旗下专注于客户出差旅行的专业管理服务公司",
  A115: "公司成立于2017年，注册资金3000万元，由国家工商行政管理局批准成立，航空机票代理人之一，被中国民用航空总局评为航空客运、货运一类资质，国际航空运输协会（IATA）一级认证资质。公司于2021年加入中国航协。成为正式机票代理人与国内、国际众多航空公司建立了合作关系，与业内有实力的同行实现了资源共享，并且与三秦都市报达成了战略合作。",
  A116: "云上国际秉承“满意服务，高于一切”的宗旨，为客户提供优质的机票、酒店、票务预订等为一体的电子商务服务平台。",
  A117: "以客户为中心，以企业为载体，创新的精神，为企业提供个性化服务",
  A118: "平台集合智能化的出行管控操作，最大程度地为企业降低差旅管控成本，提高出行效率， 为企业提供一站式差旅解决方案，有效地解决了企业在差旅过程中遇到的痛点;",
  A119: "一：差旅费用支出超标，企业资金周转压力大传统的差旅管理支出费用金额巨大。痛点",
  A120: "二：差旅管理不规范 差旅费用去向不明确传统的差旅管理过于粗放，不正规，无法掌握出差人员的差旅行程，导致出现虚报、多报等现象。",
  A121: "三：差旅成本高 无法正规管控传统出差由于企业差旅管理模式比较陈旧，造成了大量成本浪费和人力投入。痛点四：员工出差个人垫资 报销周期冗长原始出差需要员工个人先垫付，出差后凭借票据才能报销。流程",
  A122: "云上易行商旅集结了购票、住宿、审批、用车等多个差旅功能，为用户提供更便捷、更高效的差旅体验。",
  A123: "购票方面涵盖了：机票、火车票、进票渠道均从专业平台采购，票价为全网最低，同时支持极速改签、退票功能；酒店方面：平台签订合作国内国际多家酒店，随时随地预订入住；用车方面平台签订了“曹操专车”、“首汽约车”等权威供车平台，为用户提供安心的用车体验；同时平台支持全球各国各地区的签证办理功能，为用户差旅出行提供最便捷、最安心的出行体验。",
  
  A124:"7天*24 小时预定服务中心，60专业坐席的电话呼叫中心；",
  A125:"先进的客户管理系统，全面记录客户数据资料；",
  A126:"专职的客服经理确保为客户提供贴身的个性化服务，全程录音管理，方便我",
  A127:"们对服务质量和服务态度管理监控；",
  A128:"中国航信提供权威民航信息网络预定接口；",
  A129:"全国在线酒店预订系统，实现实时报价和预订功能；",
  A131:"网络和客户管理系统同步，实现数据资料自动转换；",
  A132:"客户通过网络实时查询预定机票酒店，实时预定全国机场贵宾服务和商务车接送服务，方便快捷",
  A133:"结合企业差旅制度，开发个性化差旅管理系统，直接嵌入企业员工工作平台；",
  A134:"员工通过系统，直接预订机票、酒店、机场服务、租车服务；",
  A135:"实现所有服务产品的无缝衔接，客户足不出户，享受一系列专业化差旅服务;",
  A137:"事前管控",
  A138:"组织管理，差标配置",
  A139:"审批配置，预算管理",
  A140:"消费支付",
  A141:"企业支付，实时订单详情",
  A142:"费用归属，消息通知",
  A143:"统一结算/财务入账",
  A144:"标准订单，统一发票",
  A145:"生成财务数据，一次入账",
  A146:"数据分析",
  A147:"产品线消费分析，项目消费分析",
  A148:"节省分析，浪费分析",
  A149:"一站式采购",
  A150:"差旅出行消费一站解决",
  A151:"杜绝跨平台消费的困扰",
  A152:"严选服务",
  A153:"海量供应商严选资源",
  A154:"总能找到您想要的低价",
  A155:"统一结算",
  A156:"月结垫资服务",
  A157:"避免反复请款，不占用企业资金流",
  A158:"优先服务",
  A159:"优先候补与出票，无票抢票",
  A160:"截登前国内电子登机牌打印",
  A161:"顶级服务",
  A162:"客户经理全程协助",
  A163:"可单项定制，区别普通于服务标准",
  A164:"VIP服务",
  A165:"7*24小时在线服务",
  A166:"2对1微信群处理，高速响应",
  A167:"人工服务咨询   7×15 小时",
  A168:"咨询时间（9:00-24:00）",
  A168:"Consultation time（9:00-24:00）",
  A169:"云上科技集团作为专业商旅系统提供商，云上科技集团自主研发系统，已拥有多项软件著作权的在线商旅预订和管理系统",
  A170:"云上科技集团注册资金3000万元，提供以企业差旅为核心，通过员工出行大数据分析，优化企业出行管理，全方位向企业提供国内外、机票，酒店、火车票、出行车辆、机场贵宾厅等全方位便捷一站式服务平台。通过信息化解决方案，企业大客户集中采购等资源整合，节约现金流和企业经营成本，实现资源合理利用。",
  A171:"云上科技集团由国家工商行政管理局批准成立，航空机票代理人之一，被中国民用航空总局评为航空客运、货运一类资质，国际航空运输协会（IATA）一级认证资质。",
  A172:"去体验",
  A173:"支持全球多个国家签证办理，资料提交、办理流程，一对一签证专员全程指导跟踪，提升签证办理效率",
  A174:"与多家知名保险公司签约合作，为您的差旅安全保驾护航，提供最安心、最可靠的出行保障 ",
  

};

import store from '../store'
export const listenerScrollPosition = (callback) => {
    window.addEventListener('scroll', callback)
}

export const cancelListenerScrollPosition = (callback) => {
    window.removeEventListener('scroll', callback)
}

export const jumpRouter = (item) =>{
  if(item.type==0){
    store.state.navIndex=item.navIndex
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }else {
    window.open('https://pc.ystmc.com')
  }
}

import Vue from "vue";
import App from "./App.vue";
import i18n from "./lang";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import router from "./router";
import store from "./store";
import { jumpRouter } from "@/utils/index";

Vue.prototype.jumpRouter = jumpRouter;
Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
